import "./QuestionPage.scss";

import React, {useState, useRef } from 'react';
import {LanguageContext} from "../../App";


import FlagSwiper from "./Components/FlagSwiper/FlagSwiper";
import {useContext} from "react";

import translations from "./json-data/translations.json";

const QuestionPage = () => {

    const [language] = useContext(LanguageContext)

    const [srcVideo, setSrcVideo] = useState(null)
    const vidRef = useRef(null);

    const playQuestionHandler = (questionNum) => {
        setSrcVideo(translations[`${language}`][`question${questionNum}`]?.videoSrc)

        setTimeout(() => {
            vidRef.current.play();
        }, 100);
    }

    return (
        <div className="question-page">
            <FlagSwiper />
            <div className="questions-container">
                <div className="questions-set">
                    <div className="question" onClick={() => playQuestionHandler(1)}>
                        <span>{translations[`${language}`].question1?.title}</span>
                    </div>
                    <div className="question" onClick={() => playQuestionHandler(2)} >
                        <span>{translations[`${language}`].question2?.title}</span>
                    </div>
                    <div className="question" onClick={() => playQuestionHandler(3)} >
                        <span>{translations[`${language}`].question3?.title}</span>
                    </div>
                    <div className="question" onClick={() => playQuestionHandler(4)} >
                        <span>{translations[`${language}`].question4?.title}</span>
                    </div>
                    <div className="question" onClick={() => playQuestionHandler(5)} >
                        <span>{translations[`${language}`].question5?.title}</span>
                    </div>
                </div>

                <div className="questions-set">
                    <div className="question" onClick={() => playQuestionHandler(6)} >
                        <span>{translations[`${language}`].question6?.title}</span>
                    </div>
                    <div className="question" onClick={() => playQuestionHandler(7)} >
                        <span>{translations[`${language}`].question7?.title}</span>
                    </div>
                    <div className="question" onClick={() => playQuestionHandler(8)} >
                        <span>{translations[`${language}`].question8?.title}</span>
                    </div>
                    <div className="question" onClick={() => playQuestionHandler(9)} >
                        <span>{translations[`${language}`].question9?.title}</span>
                    </div>
                    <div className="question" onClick={() => playQuestionHandler(10)} >
                        <span>{translations[`${language}`].question10?.title}</span>
                    </div>
                </div>
            </div>
            <div className="video-container">
                <img className="humans-logo" src="/assets/images/humans-ai-logo.svg" alt=""/>
                <img className="guide-logo" src="/assets/images/ai-guide-logo.svg" alt=""/>
                <video ref={vidRef} controls={false} src={srcVideo}>
                </video>
            </div>
        </div>
    );
};

export default QuestionPage;
