import React, {useContext } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import './FlagSwiper.scss';
import { LanguageContext } from "../../../../App";
import translations from "../../json-data/translations.json";

const FlagSwiper = (props) => {
    const [language, setLanguage] = useContext(LanguageContext)

    const changeLanguageHandler = (language) => {
        setLanguage(language)
    }

    return (
        <div className="swiper-container">
            <Swiper threshold={0.5} spaceBetween={20} shortSwipes={false} slidesPerView="auto">
                <SwiperSlide>
                    <div onClick={() => changeLanguageHandler("en")} className={"flag-container" + (language === 'en' ? ' active' : '')} >
                        <img src={translations.en.flag} alt=""/>
                        <span>{translations.en.language}</span>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div onClick={() => changeLanguageHandler("de")} className={"flag-container" + (language === 'de' ? ' active' : '')} >
                        <img src={translations.de.flag} alt=""/>
                        <span>{translations.de.language}</span>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div onClick={() => changeLanguageHandler("fr")} className={"flag-container" + (language === 'fr' ? ' active' : '')} >
                        <img src={translations.fr.flag} alt=""/>
                        <span>{translations.fr.language}</span>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div onClick={() => changeLanguageHandler("es")} className={"flag-container" + (language === 'es' ? ' active' : '')} >
                        <img src={translations.es.flag} alt=""/>
                        <span>{translations.es.language}</span>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div onClick={() => changeLanguageHandler("pt")} className={"flag-container" + (language === 'pt' ? ' active' : '')} >
                        <img src={translations.pt.flag} alt=""/>
                        <span>{translations.pt.language}</span>
                    </div>
                </SwiperSlide>
                <SwiperSlide >
                    <div onClick={() => changeLanguageHandler("it")} className={"flag-container" + (language === 'it' ? ' active' : '')} >
                        <img src={translations.it.flag} alt=""/>
                        <span>{translations.it.language}</span>
                    </div>
                </SwiperSlide>
                <SwiperSlide >
                    <div onClick={() => changeLanguageHandler("ro")} className={"flag-container" + (language === 'ro' ? ' active' : '')} >
                        <img src={translations.ro.flag} alt=""/>
                        <span>{translations.ro.language}</span>
                    </div>
                </SwiperSlide>
                <SwiperSlide >
                    <div onClick={() => changeLanguageHandler("at")} className={"flag-container" + (language === 'at' ? ' active' : '')} >
                        <img src={translations.at.flag} alt=""/>
                        <span>{translations.at.language}</span>
                    </div>
                </SwiperSlide>
                <SwiperSlide >
                    <div onClick={() => changeLanguageHandler("be")} className={"flag-container" + (language === 'be' ? ' active' : '')} >
                        <img src={translations.be.flag} alt=""/>
                        <span>{translations.be.language}</span>
                    </div>
                </SwiperSlide>
                <SwiperSlide >
                    <div onClick={() => changeLanguageHandler("hr")} className={"flag-container" + (language === 'hr' ? ' active' : '')} >
                        <img src={translations.hr.flag} alt=""/>
                        <span>{translations.hr.language}</span>
                    </div>
                </SwiperSlide>
                <SwiperSlide >
                    <div onClick={() => changeLanguageHandler("cy")} className={"flag-container" + (language === 'cy' ? ' active' : '')} >
                        <img src={translations.cy.flag} alt=""/>
                        <span>{translations.cy.language}</span>
                    </div>
                </SwiperSlide>
                <SwiperSlide >
                    <div onClick={() => changeLanguageHandler("cz")} className={"flag-container" + (language === 'cz' ? ' active' : '')} >
                        <img src={translations.cz.flag} alt=""/>
                        <span>{translations.cz.language}</span>
                    </div>
                </SwiperSlide>
                <SwiperSlide >
                    <div onClick={() => changeLanguageHandler("dk")} className={"flag-container" + (language === 'dk' ? ' active' : '')} >
                        <img src={translations.dk.flag} alt=""/>
                        <span>{translations.dk.language}</span>
                    </div>
                </SwiperSlide>
                <SwiperSlide >
                    <div onClick={() => changeLanguageHandler("ee")} className={"flag-container" + (language === 'ee' ? ' active' : '')} >
                        <img src={translations.ee.flag} alt=""/>
                        <span>{translations.ee.language}</span>
                    </div>
                </SwiperSlide>
                <SwiperSlide >
                    <div onClick={() => changeLanguageHandler("fi")} className={"flag-container" + (language === 'fi' ? ' active' : '')} >
                        <img src={translations.fi.flag} alt=""/>
                        <span>{translations.fi.language}</span>
                    </div>
                </SwiperSlide>
                <SwiperSlide >
                    <div onClick={() => changeLanguageHandler("gr")} className={"flag-container" + (language === 'gr' ? ' active' : '')} >
                        <img src={translations.gr.flag} alt=""/>
                        <span>{translations.gr.language}</span>
                    </div>
                </SwiperSlide>
                <SwiperSlide >
                    <div onClick={() => changeLanguageHandler("hu")} className={"flag-container" + (language === 'hu' ? ' active' : '')} >
                        <img src={translations.hu.flag} alt=""/>
                        <span>{translations.hu.language}</span>
                    </div>
                </SwiperSlide>
                <SwiperSlide >
                    <div onClick={() => changeLanguageHandler("ie")} className={"flag-container" + (language === 'ie' ? ' active' : '')} >
                        <img src={translations.ie.flag} alt=""/>
                        <span>{translations.ie.language}</span>
                    </div>
                </SwiperSlide>
                <SwiperSlide >
                    <div onClick={() => changeLanguageHandler("lv")} className={"flag-container" + (language === 'lv' ? ' active' : '')} >
                        <img src={translations.lv.flag} alt=""/>
                        <span>{translations.lv.language}</span>
                    </div>
                </SwiperSlide>
                <SwiperSlide >
                    <div onClick={() => changeLanguageHandler("lt")} className={"flag-container" + (language === 'lt' ? ' active' : '')} >
                        <img src={translations.lt.flag} alt=""/>
                        <span>{translations.lt.language}</span>
                    </div>
                </SwiperSlide>
                <SwiperSlide >
                    <div onClick={() => changeLanguageHandler("lu")} className={"flag-container" + (language === 'lu' ? ' active' : '')} >
                        <img src={translations.lu.flag} alt=""/>
                        <span>{translations.lu.language}</span>
                    </div>
                </SwiperSlide>
                <SwiperSlide >
                    <div onClick={() => changeLanguageHandler("mt")} className={"flag-container" + (language === 'mt' ? ' active' : '')} >
                        <img src={translations.mt.flag} alt=""/>
                        <span>{translations.mt.language}</span>
                    </div>
                </SwiperSlide>
                <SwiperSlide >
                    <div onClick={() => changeLanguageHandler("nl")} className={"flag-container" + (language === 'nl' ? ' active' : '')} >
                        <img src={translations.nl.flag} alt=""/>
                        <span>{translations.nl.language}</span>
                    </div>
                </SwiperSlide>
                <SwiperSlide >
                    <div onClick={() => changeLanguageHandler("pl")} className={"flag-container" + (language === 'pl' ? ' active' : '')} >
                        <img src={translations.pl.flag} alt=""/>
                        <span>{translations.pl.language}</span>
                    </div>
                </SwiperSlide>
                <SwiperSlide >
                    <div onClick={() => changeLanguageHandler("sk")} className={"flag-container" + (language === 'sk' ? ' active' : '')} >
                        <img src={translations.sk.flag} alt=""/>
                        <span>{translations.sk.language}</span>
                    </div>
                </SwiperSlide>
                <SwiperSlide >
                    <div onClick={() => changeLanguageHandler("si")} className={"flag-container" + (language === 'si' ? ' active' : '')} >
                        <img src={translations.si.flag} alt=""/>
                        <span>{translations.si.language}</span>
                    </div>
                </SwiperSlide>
                <SwiperSlide >
                    <div onClick={() => changeLanguageHandler("se")} className={"flag-container" + (language === 'se' ? ' active' : '')} >
                        <img src="/assets/images/flags/sweden.svg" alt=""/>
                        <span>Swedish</span>
                    </div>
                </SwiperSlide>
            </Swiper>
        </div>
    );
};


export default FlagSwiper;
