import './App.css';
import React, {useState} from "react";
import {BrowserRouter,Route, Routes } from "react-router-dom";
import QuestionPage from "./Pages/question-page/QuestionPage";
import SlotMachinePage from "./Pages/slot-page/SlotMachinePage";

export const LanguageContext = React.createContext(null);

function App() {

const [language, setLanguage] = useState("en");

  return (
    <div className="App">
    <LanguageContext.Provider value={[language, setLanguage]}>
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<QuestionPage />}>

                </Route>
                <Route path="/slot-machine" element={<SlotMachinePage />}>

                </Route>
            </Routes>
        </BrowserRouter>
    </LanguageContext.Provider>
    </div>
  );
}

export default App;
